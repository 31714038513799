<!-- eslint-disable -->
<template>
  <v-container id="dashboard" fluid tag="section">
  <b-modal v-model="showLoginModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Détail de poste
    </template>
    {{packageDetail.message}}
    <v-col cols="12" class="text-right">
      <v-btn @click="showLoginModal=false" style="background-color:#4caf50" class="mr-0">
        FERMER
      </v-btn>
    </v-col>
    <v-container class="py-0">
    </v-container>
  </b-modal>
  <b-modal v-model="showPostModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Ajouter un chauffeur
    </template>
    <div style="width:100%;text-align:center"><span style="color:red">{{newPostErrorMessage}}</span></div>
    <v-container class="py-0">
        
        <v-col cols="12" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Ajouter
          </v-btn>
        </v-col>
    </v-container>
  </b-modal>
  <base-material-card icon="mdi-package-variant-closed" title="Liste des messages" class="px-5 py-3">
  <template v-slot:heading>
    <v-icon @click="reloadItems">mdi-reload</v-icon>
  </template>
   <!-- <div style="width:100%;text-align:right">
     <v-btn color="success" small depressed @click="showPostModal = true">Ajouter un chaffeur</v-btn>
   </div> -->
    <vue-good-table
      max-height="700px"
      :columns="columns"
      :isLoading.sync="isLoading"
      @on-row-click="rowClick"
      :rows="rows"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:15}"
      style-class="vgt-table condensed bordered tableOne"
    >
    <template slot="loadingContent">
        <div class="spinner spinner-primary mx-auto my-auto"></div>
    </template>
    <template slot="table-row" slot-scope="props">
        <span style="color:red;" v-if="props.column.field == 'incoming_packages'">
          {{props.row[props.column.field]}}
        </span>
        <span  v-else-if="props.column.field == 'name'">
          {{props.row[props.column.field]}}
          <v-icon v-if="props.row.dlv_type == 'homedlv'" style="float:right;" color='green'>mdi-home</v-icon>
          <v-icon v-if="props.row.dlv_type == 'stopdesk'" style="float:right;" color='green'>mdi-bank-outline</v-icon>
        </span>
        <span  v-else-if="props.column.field == 'creation_date'">
          <v-badge v-if="props.row.message_read == false" color="red" overlap bordered>
              <template v-slot:badge>
                <span>new</span>
              </template>
              {{moment(props.row.creation_date).format('DD/MM/YYYY HH:mm')}}
            </v-badge>
          <span v-else>
            {{moment(props.row.creation_date).format('DD/MM/YYYY HH:mm')}}
          </span>
        </span>
        <span  v-else-if="props.column.field == 'action'">
          <v-icon id="popover-reactive-1" @click="showConfirmation(props.row)" style="color:#dc3545;margin-left:10px;">mdi-close-circle</v-icon>
          <v-icon @click="goToPost(props.row)" style="color:blue;margin-left:10px;">mdi-open-in-new</v-icon>
          <b-popover
            target="popover-reactive-1"
            triggers=""
            :show.sync="popoverShow"
            placement="auto"
            container="my-container"
            ref="popover"
          >
            <template #title>
              CONFIRMATION
            </template>
            <b-button @click="onClose" size="sm" style="margin-right:10px" variant="danger">Cancel</b-button>
            <b-button @click="deleteDriver" size="sm" variant="primary">Ok</b-button>
          </b-popover>
        </span>
        <span style="color:#4caf50;" v-else-if="props.column.field == 'outgoing_packages'">
          {{props.row[props.column.field]}}
        </span>
        <span v-else>
          {{props.row[props.column.field]}}
        </span>
    </template>
    </vue-good-table>
  </base-material-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'CenterDashboard',
  data () {
    return {
      // searchTerm: '',
      moment: moment,
      newPostErrorMessage: null,
      wilayas: [],
      communues: [],
      selectedWilaya: {
        name:null,
        communues: []
      },
      statusList: ['En attente', 'Accepté', 'Réfusé'],
      selectedCommunue: null,
      showPackageModal: false,
      showHisotryModal: false,
      isLoading: false,
      totalRows: 0,
      tableFilters: {
        approved: ''
      },
      outgoingTableFilters: {
        id: null,
        name: null,
        status: null,
        from_wilaya: null,
        date: null
      },
      history: {
        wilaya: null,
        communue: null,
        tracking_id: null,
        show: false,
        message: null,
        creation_date: {
          date: null,
          time: null
        }
      },
      packageDetail: {
        home_fees:0,
      },
      columns:[
        {
          label: 'Date',
          field: 'creation_date',
          width: '150px',
        },
        {
          label: 'Nom',
          field: 'name',
          width: '200px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Numéro de téléphone',
          field: 'phone',
          width: '200px',
          filterOptions: {
            enabled:true,
            placeholder: 'Enter le numéro',
            // trigger: 'enter',
          }
        },
        {
          label: 'Email',
          field: 'email',
          width: '150px',
          filterOptions: {
            enabled:true,
            placeholder: 'Enter l\'email',
          }
        },
        {
          label: 'Wilaya',
          field: 'wilaya',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: 'Choisir la wilaya',
          },
        },
        // {
        //   label: 'Entrants',
        //   field: 'incoming_packages',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
        // {
        //   label: 'Sortants',
        //   field: 'outgoing_packages',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
        // {
        //   label: 'Livrée',
        //   field: 'total_delivered',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
        // {
        //   label: 'Returned',
        //   field: 'total_returned',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
      ],
      newPost: {
        email:null,
        password:null,
        wilaya:null,
        communue:null,
        phone:null,
        address:null,
        name: null,
        home_fees: null,
        code: null
      },
      rows: [],
      outgoingRows: [],
      showLoginModal: false,
      showPostModal: false,
      preparedRow: null,
      preparedAction: null,
      feesExceptions: [],
      popoverShow : false,
      selectedRow:null
    }
  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    this.getWilayas()
    this.loadMessages()
    // this.columns[4].filterOptions.filterDropdownItems = this.statusList
    this.$set(this.columns[4].filterOptions, 'filterDropdownItems', this.statusList);
  },
  methods: {
    reloadItems() {
      this.loadMessages()
      this.isLoading = true
    },
    showConfirmation(row) {
      this.popoverShow = true
      this.selectedRow = row
    },
    deleteDriver() {
      axios.post('delete-driver',{
        auth_token: localStorage.getItem('auth_token'),
        code: this.selectedRow.code
      }).then(response =>{
        this.loadMessages()
      })
    },
    onClose(){
      this.popoverShow = false
    },
    delete_post(){
      axios.post('delete-post',{auth_token: localStorage.getItem('auth_token'),
      code: this.packageDetail.code}).then(response =>{
        this.popoverShow = false
        this.showLoginModal = false
        this.loadMessages()
      })
    },
    getWilayas() {
      axios.post('get-wilayas').then(response => {
        if (response.data.status == 'success') {
          this.wilayas = response.data.data
          let wilayas = []
          this.wilayas.forEach(element => {
            wilayas.push(element.name)
          });
          // this.$set(this.columns[2].filterOptions, 'filterDropdownItems', wilayas);
          // this.columns[2].filterOptions.filterDropdownItems = wilayas
        }
      })
    },
    prepareAction(row,action) {
      this.preparedRow = row
      this.preparedAction = action
      this.showLoginModal = true
    },
    confirmAction() {
      if (this.preparedAction == 'accept') this.acceptProvider(this.preparedRow)
      else if (this.preparedAction == 'refuse') this.refuseProvider(this.preparedRow)
      this.showLoginModal = false
      this.loadMessages()
    },
    handleModalHide(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    hideModal(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    loadMessages() {
      axios.post('admin-messages', {
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        if (response.data.status == 'success')
          this.rows = response.data.data
      })
    },
    goToPost(packageDetail) {
      localStorage.setItem('admin_auth_token',localStorage.getItem('auth_token'))
      localStorage.setItem('auth_token',packageDetail.auth_token)
      window.location.replace("driver-dashboard");
    },
    rowClick(params) {
      // if (!this.showOrderWithDuplicates && params.event.target.localName !== "input" && params.event.target.localName !== "select" && params.event.target.localName !== "option" && params.event.target.className !== "vgt-checkbox-col" && params.event.target.className !== "dropdown-item" && params.event.target.type !== "button" && params.event.target.tagName !== "A") {
      this.packageDetail = params.row
      this.showLoginModal = true
      this.packageDetail.message_read = true
      axios.post('admin-set-message',{
        auth_token: localStorage.getItem('auth_token'),
        id:this.packageDetail.id,
        read:true,
      })
    },
    changeStatus(row, tableType) {
      axios.post('center-change-status', {
        auth_token: localStorage.getItem('auth_token'),
        tracking_id: row.tracking_id,
        new_status: row.status
      }).then(response => {
        if (response.data.status == 'success') {
          if (tableType == 'incoming') this.loadIncomingItems()
          else if (tableType == 'outgoing') this.loadOutgoingItems()
        }
      })
    },
    onColumnFilter(params) {
      console.log(params)
      this.tableFilters = params.columnFilters
      if (params.columnFilters.approved_value == this.statusList[0]) this.tableFilters.approved = null
      else if (params.columnFilters.approved_value == this.statusList[1]) this.tableFilters.approved = true
      else if (params.columnFilters.approved_value == this.statusList[2]) this.tableFilters.approved = false
      else this.tableFilters.approved = ''
      this.loadMessages()
    },
    onSortChange(params) {
      let t = params[0].type
      let f = params[0].field
      this.rows.sort(function(a, b) {
        if (t == 'asc') {
          if ( a[f] < b[f] ){
            return -1;
          }
          if ( a[f] > b[f] ){
            return 1;
          }
        }
        else if (t == 'desc') {
          if ( a[f] < b[f] ){
            return 1;
          }
          if ( a[f] > b[f] ){
            return -1;
          }
        }
      });
    },
    onPageChange(params) {
      console.log(params)
    },
    onPerPageChange(params) {
      console.log(params)
    },
    // this.$set(this.columns[5].filterOptions, 'filterValue', '');
    refuseProvider(row) {
      axios.post('admin-refuse-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = false
        }
      })
    },
    acceptProvider(row) {
      axios.post('admin-accept-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = true
        }
      })
    },
    addFeesException() {
      this.feesExceptions.push({
        post_code: null,
        fees: null
      })
    },
    removeFeesExeception(index) {
      this.feesExceptions.splice(index,1)
    },
    changeFees() {
      axios.post('admin-change-post-fees', {
        auth_token: localStorage.getItem('auth_token'),
        code: this.packageDetail.code,
        home_fees: this.packageDetail.home_fees,
        fees_exceptions: this.feesExceptions,
        dlv_type: this.packageDetail.dlv_type,
      }).then(response => {
        if (response.data.status == 'success') {
          this.showLoginModal = false
          this.loadMessages()
        }
      })
    },
    addNewDriver() {
      axios.post('admin-add-driver', {
        auth_token: localStorage.getItem('auth_token'),
        post_info: this.newPost,
        fees_exceptions: this.feesExceptions
      }).then(response => {
        if (response.data.status == 'success') {
          this.loadMessages()
          this.showPostModal = false
          this.newPost= {
            email:null,
            password:null,
            wilaya:null,
            communue:null,
            phone:null,
            address:null,
            name: null,
            home_fees: null,
            code: null
          }
          this.feesExceptions = []
          this.newPostErrorMessage = null
        } else {
          this.newPostErrorMessage = response.data.error_message
        }
      })
    },
  },
}
</script>
<style>
.vgt-responsive {
  min-height: 450px;
}
</style>
